/**-----------------------------------------------------------------------------------
    CSS INDEX
    ===================

    1. Default
    2. Header
    3. Navigation Menu
        - Desktop Navigation
        - Mobile Navigation
    4. Main Content

 */
/*--------------------------------------------------------------
    1. Default
--------------------------------------------------------------*/
@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('../public/fonts/AvenirNextLTPro-Demi.eot');
    src: url('../public/fonts/AvenirNextLTPro-Demi.eot?#iefix') format('embedded-opentype'),
        url('../public/fonts/AvenirNextLTPro-Demi.woff2') format('woff2'),
        url('../public/fonts/AvenirNextLTPro-Demi.woff') format('woff'),
        url('../public/fonts/AvenirNextLTPro-Demi.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('../public/fonts/AvenirNextLTPro-Bold.eot');
    src: url('../public/fonts/AvenirNextLTPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../public/fonts/AvenirNextLTPro-Bold.woff2') format('woff2'),
        url('../public/fonts/AvenirNextLTPro-Bold.woff') format('woff'),
        url('../public/fonts/AvenirNextLTPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('../public/fonts/AvenirNextLTPro-Regular.eot');
    src: url('../public/fonts/AvenirNextLTPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('../public/fonts/AvenirNextLTPro-Regular.woff2') format('woff2'),
        url('../public/fonts/AvenirNextLTPro-Regular.woff') format('woff'),
        url('../public/fonts/AvenirNextLTPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

* {
    outline-color: transparent;
    outline-style: none;
}

:root {
    --white: #FFFFFF;
    --light: #F2F6FF;
    --dark: #0d0d0d; 
    --gray: #A4A9B2;
    --primary: #4285F4;
    --success: #34A853;
    --danger: #EA4335;
    --warning: #FFBF1C;
    --info: #4285F4;
    --border: #EDEDED;
    --font-white: #FFFFFF;
    --box-shadow: 0 0 6px -4px #00000014, 0 0 16px 0 #0000000d, 0 0 28px 8px #0000000d;
}
body {
    font-family: 'Avenir Next LT Pro', sans-serif;
    color: var(--dark);
    font-size: 16px;
    background-color: var(--light);
    overflow-x: hidden;
    scroll-behavior: smooth;
}
::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
}
::-webkit-scrollbar-track {
    background-color: transparent !important;
}
::-webkit-scrollbar-thumb {
    background: var(--border);
    border-radius: .325rem;
}
::selection {
    color: var(--font-white);
    background-color: var(--primary);
}
::-moz-selection {
    color: var(--font-white);
    background-color: var(--primary);
}
a {
    color: mediumblue;
    text-decoration: none;
}
a:hover, a:focus{
    text-decoration: none;
}
a:hover {
    color: mediumblue;
}
a:-webkit-any-link:focus-visible {
    outline-offset: 0;
}
a:-webkit-any-link:focus-visible {
    outline-offset: unset;
}
/*  ==================================================
    1. Header
================================================== */
.headers{
    display: flex;
    justify-content: center;
    padding-top: 45px;
    height: 300px;
    background-color: var(--primary);
    margin-bottom: -150px;
}
.headers .logo img {
    height: 60px;
}
@media (max-width: 1199.98px) {
}

@media (max-width: 991.98px) {
    .headers {
        padding-top: 45px;
        height: 250px;
        margin-bottom: -100px;
    }
    .headers .logo img {
        height: 50px;
    }
}

@media (max-width: 767.98px) {
    .headers {
        padding-top: 25px;
        height: 200px;
        margin-bottom: -100px;
    }
}

@media (max-width: 575.98px) {
}

@media (max-width: 319.98px) {
    .header {
        height: 190px;
    }
    .headers .logo img {
        height: 40px;
    }
 }

/*  ==================================================
    2. Screen Mirror
================================================== */
.screen-mirror-box{
    background-color: var(--white);
    border-radius: .625rem;
    box-shadow: 0 0 6px 1px #0000000f;
    margin: 0 1rem;
}
.screen-mirror-box .screen-mirror-media{
    position: relative;
    padding-top: 3rem;
}
.screen-mirror-box .screen-mirror-media svg{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.screen-mirror-box .screen-mirror-media .qr-code::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 252px;
    height: 252px;
    background: url(../public/img/qr-code-frame.svg);
    margin: 24px 0;
}
.screen-mirror-box .screen-mirror-content{
    text-align: center;
}
.screen-mirror-box .screen-mirror-content h1{
    font-size: 3rem;
    font-weight: 600;
}
.screen-mirror-box .screen-mirror-content p{
    font-size: 20px;
    color: var(--gray);
    margin: .75rem 0 1.5rem;
}
.screen-mirror-box .screen-mirror-content .app-store-btn img {
    height: 60px;
}
.screen-mirror-box .screen-mirror-content .app-store-btn{
    display: inline-block;
    margin: 0 .5rem 1rem;
}
.screen-mirror-box .screen-mirror-info {
    border-top: 1px solid var(--border);
    padding: 2rem;
}
.screen-mirror-box .screen-mirror-info ul {
    padding-left: 1rem;
    margin-bottom: 0;
}
.screen-mirror-box .screen-mirror-info li {
    font-weight: 500;
    margin-bottom: 0.25rem;
}
.screen-mirror-box .screen-mirror-info ul li:last-child{
    margin-bottom: 0;
}
@media (max-width: 1699.98px) {
    .screen-mirror-box .pe-lg-5,
    .screen-mirror-box .ps-lg-5{
        padding: 0 !important;
    }
}
@media (max-width: 1399.98px) {
    .screen-mirror-box .screen-mirror-content{
        text-align: left;
    }
    .screen-mirror-box .screen-mirror-content .app-store-btn{
        margin-left: 0;
        margin-right: 1rem;
    }
}
@media (max-width: 991.98px) {
    .screen-mirror-box .screen-mirror-media img{
        display: none;
    }
    .screen-mirror-box .screen-mirror-media svg{
        position: unset;
        transform: none;
    }
    .screen-mirror-box .screen-mirror-media .qr-code::before{
        margin: 35px 0;
    }
    .screen-mirror-box .screen-mirror-content{
        margin-top: 4rem;
        text-align: center;
        padding: 0 .5rem;
    }
    .screen-mirror-box .screen-mirror-content h1{
        font-size: 2.5rem;
    }
    .screen-mirror-box .screen-mirror-content p {
        font-size: 18px;
    }
    .screen-mirror-box .screen-mirror-info {
        margin-top: 2rem;
    }
    .screen-mirror-box .screen-mirror-content .app-store-btn{
        margin: 0 .5rem .5rem;
    }
}

@media (max-width: 767.98px) {  
}

@media (max-width: 575.98px) {
    .screen-mirror-box {
        margin: 0 .5rem;
    }
    .screen-mirror-box .screen-mirror-content h1 {
        font-size: 2rem;
    }
}

@media (max-width: 319.98px) {
    .screen-mirror-box .screen-mirror-media svg{
        width: 175px;
        height: 175px;
    }
    .screen-mirror-box .screen-mirror-media .qr-code::before{
        display: none;
    }
    .screen-mirror-box .screen-mirror-media{
        padding-top: 1rem;
    }
    .screen-mirror-box .screen-mirror-content {
        margin-top: 2rem;
        text-align: center;
    }
    .screen-mirror-box .screen-mirror-content h1 {
        font-size: 2rem;
    }
    .screen-mirror-box .screen-mirror-content p {
        font-size: 16px;
    }
    .screen-mirror-box .screen-mirror-content .app-store-btn img{
        height: 60px;
    }
    .screen-mirror-box .screen-mirror-info{
        padding: 1rem;
    }
    .screen-mirror-box .screen-mirror-info li{
        font-size: 15px;
    }
}
.btn-info{
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    display: flex;
    align-items:center;
    justify-content: center;
    background-color: var(--primary);
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.btn-info i {
    font-size: 30px;
    color: var(--font-white);
}
@media (max-width: 767.98px) {  
    .btn-info{
        bottom: 1.5rem;
        right: 1.5rem;
    }
}

@media (max-width: 575.98px) {
    .btn-info{
        bottom: 1rem;
        right: 1rem;
    }
}
/*  ==================================================
    2. Screen Mirror
================================================== */
@media (max-width: 1399.98px) {
    .screen-mirror-view{
        display: none;
    }
}
/*  ==================================================
    3. Screen Mirror About
================================================== */
.scr-mir-cont {
    padding: 2rem 1.25rem;
}
.scr-mir-cont h1 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 1.5rem;
}
.scr-mir-cont h6{
    font-weight: 600;
    margin-top: 2rem;
    margin-bottom: 1rem;
}
.scr-mir-cont p,
.scr-mir-cont p a{
    word-break: break-word;
}
.scr-mir-med {
    position: relative;
    padding-top: 50%;
    margin: 2rem 1.25rem 0;
    overflow: hidden;
}
.scr-mir-med.why{
    padding-top: 40%;
}
.scr-mir-med iframe,
.scr-mir-med img{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center center;
    object-position: center center;
    border-radius: .625rem;
}
@media (max-width: 767.98px) {  
}

@media (max-width: 575.98px) {
    .scr-mir-med {
        margin: .75rem 0 0;
    }
    .scr-mir-cont {
        padding: 1rem 0;
    }
    .scr-mir-cont h1 {
        font-size: 24px;
        margin-bottom: .5rem;
    }
    .scr-mir-cont h6 {
        margin-top: 1rem;
    }
}

@media (max-width: 319.98px) {
}